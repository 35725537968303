<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-n3">
        Given that equal volumes of the following pairs of solutions are mixed, which
        <b>pair</b> will produce a buffer solution?
      </p>

      <v-radio-group v-model="inputs.input1" class="ml-6 mb-n6" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsShuffled"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
          :label="option.text"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'albertaGenChemQuiz3Question42',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content ?? 1;
    },
    options() {
      if (this.versionNumber.value === 1) {
        return [
          {text: '0.10 M $\\ce{HCl}$ and 0.15 M $\\ce{NH3}$', value: 'HCl_NH3'},
          {text: '0.15 M $\\ce{HCl}$ and 0.20 M $\\ce{CH3COOH}$', value: 'HCl_CH3COOH'},
          {text: '0.10 M $\\ce{HCl}$ and 0.050 M $\\ce{NaOH}$', value: 'HCl_NaOH'},
          {text: '0.10 M $\\ce{HCl}$ and 0.20 M $\\ce{NaCl}$', value: 'HCl_NaCl'},
          {text: '0.10 M $\\ce{HCl}$ and 0.050 M $\\ce{NaCH3COO}$', value: 'HCl_CH3COO'},
          {text: 'None of these will produce a buffer solution', value: 'none'},
        ];
      } else if (this.versionNumber.value === 2) {
        return [
          {text: 'None of these will produce a buffer solution', value: 'none'},
          {text: '0.10 M $\\ce{HCl}$ and 0.20 M $\\ce{CH3COOH}$', value: 'HCl_CH3COOH'},
          {text: '0.10 M $\\ce{HCl}$ and 0.10 M $\\ce{NaOH}$', value: 'HCl_NaOH2'},
          {text: '0.20 M $\\ce{HCl}$ and 0.30 M $\\ce{NH3}$', value: 'HCl_NH3'},
          {text: '0.30 M $\\ce{HCl}$ and 0.10 M $\\ce{NaCH3COO}$', value: 'HCl_CH3COO'},
          {text: '0.05 M $\\ce{HCl}$ and 0.20 M $\\ce{NaCl}$', value: 'HCl_NaCl'},
        ];
      } else if (this.versionNumber.value === 3) {
        return [
          {text: '0.20 M $\\ce{HCl}$ and 0.10 M $\\ce{NaCH3COO}$', value: 'HCl_CH3COO'},
          {text: '0.15 M $\\ce{HCl}$ and 0.20 M $\\ce{NH3}$', value: 'HCl_NH3'},
          {text: '0.10 M $\\ce{HCl}$ and 0.20 M $\\ce{NaCl}$', value: 'HCl_NaCl'},
          {text: 'None of these will produce a buffer solution', value: 'none'},
          {text: '0.050 M $\\ce{HCl}$ and 0.050 M $\\ce{NaOH}$', value: 'HCl_NaOH2'},
          {text: '0.20 M $\\ce{HCl}$ and 0.20 M $\\ce{CH3COOH}$', value: 'HCl_CH3COOH'},
        ];
      } else if (this.versionNumber.value === 4) {
        return [
          {text: '0.10 M $\\ce{HCl}$ and 0.10 M $\\ce{NaOH}$', value: 'HCl_NaOH2'},
          {text: '0.25 M $\\ce{HCl}$ and 0.40 M $\\ce{NH3}$', value: 'HCl_NH3'},
          {text: 'None of these will produce a buffer solution', value: 'none'},
          {text: '0.20 M $\\ce{HCl}$ and 0.20 M $\\ce{NaCl}$', value: 'HCl_NaCl'},
          {text: '0.20 M $\\ce{HCl}$ and 0.10 M $\\ce{NaHCOO}$', value: 'HCl_HCOO'},
          {text: '0.20 M $\\ce{HCl}$ and 0.20 M $\\ce{NH4Cl}$', value: 'HCl_NH4'},
        ];
      }
    },
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
